import { Box, Grid, Paper } from "@mui/material";
import { collection } from "firebase/firestore";
import { db } from "../../firebase";

// No import is required in the WebPack.
import "@uiw/react-md-editor/markdown-editor.css";
// No import is required in the WebPack.
import "@uiw/react-markdown-preview/markdown.css";

export default function ChangelogPage() {
    const postsCollectionRef = collection(db, "posts");

    return (
        <Grid container spacing={1}>
            {/* Blog entries */}
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#0d1117', backgroundImage: 'none' }}>
                    <Box>
                        <Box className="blog-post">
                            04/05/2024:
                            <br />
                            <ul>
                                <li>Dashboard feature implemented.</li>
                                <li>Create post feature implemented.</li>
                            </ul>
                            <br />
                            04/14/2024:
                            <br />
                            <ul>
                                <li>Google analytics tag added.</li>
                                <li>User authentication implemented.</li>
                                <li>Google analytics tag added.</li>
                                <li>Showing post brief on the dashboard.</li>
                                <li>Showing social media links.</li>
                                <li>Auto hide sidebar.</li>
                            </ul>
                            <br />
                            04/14/2024:
                            <br />
                            <ul>
                                <li>Device type detection.</li>
                                <li>Auto hide navbar for Tablets and Smartphones.</li>
                            </ul>
                            <br />
                            Future changes:
                            <br />
                            <ul>
                                <li>Server side rendering. (High priority)</li>
                                <li>Use experience: Improve navigation (Refactor routing).</li>
                                <li>Enable project sections.</li>
                                <li>Improve the Look and feel</li>
                                <li>Roadmap section, tree like data structure referencing posts in every node</li>
                                <li>Newsletter subscription</li>
                            </ul>
                            <br />
                            Bugs:
                            <br />
                            <ul>
                                <li>Navlink in the navbar is not working when clicking the icon.</li>
                            </ul>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
}