import { ReactNode, Reducer, useMemo, useReducer } from "react";
import { ActionType, AuthContext, AuthValues } from "./context";
import { authReducer } from "./reducer";

const initialValues: AuthValues = {
    email: undefined
};

type Props = {
    children?: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
    /* Gets the current value from the session storage, otherwise the default value */
    const [state, dispatchAction] = useReducer<
        Reducer<AuthValues, ActionType>,
        AuthValues
    >(authReducer, initialValues, (state) => state);

    const value = useMemo(() => ({ state, dispatchAction }), [state]);

    return <AuthContext.Provider value={value}> {children} </AuthContext.Provider>;
};

export default AuthProvider;
