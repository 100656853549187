import { Dispatch, createContext } from "react";

export type AuthValues = {
    email: string | undefined;
};

export enum AuthActions {
    SET_AUTH = "SET_AUTH",
}

interface SetWinRateAction {
    type: AuthActions.SET_AUTH;
    payload: AuthValues;
}

export type ActionType = SetWinRateAction; //| RemoveProfileAction;

export type AuthType = {
    state: AuthValues;
    dispatchAction: Dispatch<ActionType>;
};

export const AuthContext = createContext<AuthType | null>(null);

AuthContext.displayName = "AuthContext";
