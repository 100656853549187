import { ActionType, OrdersValues } from ".";
import { OrdersActions } from "./context";

export const ordersReducer = (
    state: OrdersValues,
    action: ActionType
): OrdersValues => {
    switch (action.type) {
        case OrdersActions.SET_WIN_RATE: {
            return {
                ...state,
                losses: action.payload.losses,
                wins: action.payload.wins,
                totalOrders: action.payload.totalOrders
            };
        }
        default: {
            throw new Error(`Unknown action type`);
        }
    }
};
