import { Box, Grid } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { getDocs, collection, Timestamp } from "firebase/firestore";
import { db } from "../../firebase";

// No import is required in the WebPack.
import "@uiw/react-md-editor/markdown-editor.css";
// No import is required in the WebPack.
import "@uiw/react-markdown-preview/markdown.css";
import { useEffect, useState } from "react";

import "./index.css";
import { useNavigate } from 'react-router-dom';

export default function DashboardPage() {
  const navigate = useNavigate();

  const [postLists, setPostList] = useState<any>([]);

  const postsCollectionRef = collection(db, "posts"); // Waiting for rules to be updated.

  const getBriefs = async () => {
    const data = await getDocs(postsCollectionRef);
    setPostList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getBriefs();
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box className="projcard-container">
          {
            postLists.map((post: any) => {
              return (
                <Box className="projcard projcard-blue" onClick={() => navigate('/blog/' + post.id)}>
                  <Box className="projcard-innerbox">
                    {/* Brief LOGO */}
                    <img className="projcard-img" src="https://github.com/Juamava/JMV-Diagrams/blob/main/cloud-architecture-patterns/load-balancer-pattern/exampleA.png?raw=true" />

                    <Box className="projcard-textbox">
                      {/* Brief Title */}
                      <Box className="projcard-title">{post.title}</Box>
                      {/* Brief subtitle */}
                      <Box className="projcard-subtitle">Written by {post.author} at {new Timestamp(post.date.seconds, post.date.nanoseconds).toDate().toISOString()}</Box>
                      <Box className="projcard-bar"></Box>
                      <Box className="projcard-description">{post.content.substring(0, 250)}</Box>
                      <Box className="projcard-tagbox">
                        <span className="projcard-tag">HTML</span>
                        <span className="projcard-tag">CSS</span>
                      </Box>
                    </Box>
                  </Box>
                </Box>)
            })}
        </Box>
      </Grid>
    </Grid >
  );
}