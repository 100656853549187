import { Box, Grid, Paper } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Routes, Route, useParams } from 'react-router-dom';

// No import is required in the WebPack.
import "@uiw/react-md-editor/markdown-editor.css";
// No import is required in the WebPack.
import "@uiw/react-markdown-preview/markdown.css";
import { useEffect, useState } from "react";
import React from "react";
import { Helmet } from "react-helmet-async";

export default function SinglePostPage() {
  const [post, setPost] = useState<any>();
  let { postId } = useParams();

  const getPost = async (postId: any) => {
    try {
      const docRef = doc(db, "posts", postId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const postData = { ...docSnap.data(), id: docSnap.id };
        setPost(postData)
        console.log("Post Data:", postData);
        // Do something with postData
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  useEffect(() => {
    getPost(postId);
  }, [])

  useEffect(() => {
    if (post)
      document.title = document.title + ' - ' + post.title;
  }, [post])

  return (
    <Grid container spacing={1}>
      {/* Blog entries */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#0d1117', backgroundImage: 'none' }}>
          {post && <Box className="blog-post" sx={{ border: '2px solid gray' }} key={post.id}>
            <MDEditor.Markdown
              source={post.content ?? 'No content'}
              style={{ padding: '2rem' }}
            />
          </Box>}
        </Paper>
      </Grid>
    </Grid>
  );
}