import { ActionType, AuthValues } from ".";
import { AuthActions } from "./context";

export const authReducer = (
    state: AuthValues,
    action: ActionType
): AuthValues => {
    switch (action.type) {
        case AuthActions.SET_AUTH: {
            return {
                ...state,
                email: action.payload.email
            };
        }
        default: {
            throw new Error(`Unknown action type`);
        }
    }
};
