import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapIcon from '@mui/icons-material/Map';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import LayersIcon from '@mui/icons-material/Layers';
import React, { useContext, useEffect } from 'react';
import { Box, Link, List, ListSubheader, Tooltip } from '@mui/material';
import { auth } from '../../../firebase';
import { AuthContext } from '../../context/auth';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { isDesktop } from 'react-device-detect';

const drawerWidth: number = 240;

interface StyledMuiAppBarProps {
  open?: boolean;
}

const StyledMuiAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<StyledMuiAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledMuiDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


export const MainListItems = (isLoggedIn: boolean) => {
  return (
    <React.Fragment>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Blog" onClick={() => { document.location.href = '/blog' }} />
      </ListItemButton>
      <ListItemButton disabled>
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        <ListItemText primary="Roadmap" />
      </ListItemButton>
      <ListItemButton disabled>
        <ListItemIcon>
          <FolderSharedIcon />
        </ListItemIcon>
        <ListItemText primary="Projects" />
      </ListItemButton>
      {
        isLoggedIn &&
        <ListItemButton>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      }
    </React.Fragment>
  )
};

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Categories
    </ListSubheader>
    <ListItemButton component={Link} href='/changelog'>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Changelog" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Data" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Infrastructure" />
    </ListItemButton>
  </React.Fragment>
);

export const socialMediaItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Social
    </ListSubheader>
    <ListItemButton component={Link} href='https://www.linkedin.com/in/marcos-vallejo-813718a8/' target='_blank'>
      <ListItemIcon onClick={(e) => e.preventDefault()}>
        <LinkedInIcon />
      </ListItemIcon>
      <ListItemText primary="LinkedIn" />
    </ListItemButton>
    <ListItemButton component={Link} href='https://github.com/Juamava/JMV-Diagrams/tree/main' target='_blank'>
      <ListItemIcon>
        <GitHubIcon />
      </ListItemIcon>
      <ListItemText primary="GitHub" />
    </ListItemButton>
  </React.Fragment>
);

export default function AppBar() {
  const [open, setOpen] = React.useState(isDesktop);
  const authContext = useContext(AuthContext);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (<>
    <CssBaseline />
    <StyledMuiAppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ width: '11.5rem' }}
          >
            Juan Marcos Vallejo
          </Typography>
        </Box>
        {!authContext?.state.email &&
          <Box>
            <IconButton color="inherit">
              <Tooltip title="Subscribe to my newsletter." arrow placement='left' open>
                <NotificationsIcon />
              </Tooltip>
            </IconButton>
          </Box>
        }
      </Toolbar>
    </StyledMuiAppBar>
    <StyledMuiDrawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {MainListItems(authContext?.state.email ? true : false)}
        <Divider sx={{ my: 1 }} />
        {secondaryListItems}
        <Divider sx={{ my: 1 }} />
        {socialMediaItems}
      </List>
    </StyledMuiDrawer></>)
}