import "./App.css";
import { createBrowserRouter, RouterProvider, useNavigate } from "react-router-dom";
import { MainLayout } from "./shared/layouts/components/mainLayout";
import { createTheme, ThemeProvider } from "@mui/material";
import DashboardPage from "./pages/dashboard-page";
import OrdersProvider from "./shared/context/orders/provider";
import SinginPage from "./pages/singin-page";
import CreatePostPage from "./pages/create-post-page";
import React from "react";
import SinglePostPage from "./pages/single-post";
import ChangelogPage from "./pages/changelog";
import { HelmetProvider } from "react-helmet-async";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DashboardPage />,
    errorElement: <>Error</>,
  },
  {
    path: "/blog",
    element: <DashboardPage />,
    errorElement: <>Error</>,
  },
  {
    path: "/blog/:postId",
    element: <SinglePostPage />,
    errorElement: <>Error</>,
  },
  {
    path: "/create",
    element: <CreatePostPage />,
    errorElement: <>Error</>,
  },
  {
    path: "/changelog",
    element: <ChangelogPage />,
    errorElement: <>Error</>,
  },
  {
    path: "/login",
    element: <SinginPage />,
    errorElement: <>Error</>,
  },
]);

// const queryClient = new QueryClient();

const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function App() {

  return (
    <HelmetProvider>
      <ThemeProvider theme={defaultTheme}>
        <OrdersProvider>
          <MainLayout theme={defaultTheme}>
            <RouterProvider router={router} />
          </MainLayout>
        </OrdersProvider>
      </ThemeProvider>
    </HelmetProvider>);
}