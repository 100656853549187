// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_vjwDU0m18heUs3uHsfbx88-XTRJydTA",
  authDomain: "online-resume-10d72.firebaseapp.com",
  projectId: "online-resume-10d72",
  storageBucket: "online-resume-10d72.appspot.com",
  messagingSenderId: "72147792599",
  appId: "1:72147792599:web:275e82e48bf8333a983692",
  measurementId: "G-JV28SF3ECT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
