import { Box, Button, Grid, Paper } from "@mui/material";
import MDEditor, { commands, selectWord } from "@uiw/react-md-editor";
import { getDocs, collection, deleteDoc, doc, addDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";

// No import is required in the WebPack.
import "@uiw/react-md-editor/markdown-editor.css";
// No import is required in the WebPack.
import "@uiw/react-markdown-preview/markdown.css";
import { useEffect, useState } from "react";

export default function CreatePostPage() {
    const [postLists, setPostList] = useState<any>([]);
    const postsCollectionRef = collection(db, "posts");

    const getPosts = async () => {
        const data = await getDocs(postsCollectionRef);
        setPostList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const createPost = async (title: string, content: string) => {
        await addDoc(postsCollectionRef, {
            title,
            content,
            author: auth.currentUser?.displayName,
            date: new Date()
        });
    };

    useEffect(() => {
        getPosts();
    }, [])

    const [value, setValue] = useState<string>();
    return (
        <Grid container spacing={1}>
            {/* Blog entries */}
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#0d1117', backgroundImage: 'none' }}>
                    <Box className="blog-post" sx={{ border: '2px solid gray' }}>
                        <MDEditor
                            value={value}
                            onChange={(val) => setValue(val ?? '')}
                            style={{ padding: '2rem' }}
                        />
                    </Box>

                    <Button onClick={() => {
                        createPost('', value ?? '');
                    }}>Create post</Button>
                </Paper>
            </Grid>
        </Grid>
    );
}