import { Dispatch, createContext } from "react";

export type OrdersValues = {
    wins: number;
    losses: number;
    totalOrders: number;
};

export enum OrdersActions {
    SET_WIN_RATE = "SET_WIN_RATE",
}

interface SetWinRateAction {
    type: OrdersActions.SET_WIN_RATE;
    payload: OrdersValues;
}

export type ActionType = SetWinRateAction; //| RemoveProfileAction;

export type OrdersType = {
    state: OrdersValues;
    dispatchAction: Dispatch<ActionType>;
};

export const OrdersContext = createContext<OrdersType | null>(null);

OrdersContext.displayName = "OrdersContext";
