import { Box, Container, Theme, Toolbar } from "@mui/material";
import AppBar from "../../components/app-bar";
import Footer from "../../components/footer/Footer";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { AuthActions, AuthContext } from "../../context/auth";
import { auth } from "../../../firebase";

interface LayoutProps {
    children: any,
    theme: Theme,
}

export const MainLayout = ({ children, theme }: LayoutProps) => {
    const authContext = useContext(AuthContext);
    // const navigate = useNavigate();c

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                // ...
                // console.log("uid", uid)
                authContext?.dispatchAction({
                    type: AuthActions.SET_AUTH,
                    payload: {
                        email: user.email ?? undefined
                    }
                })
            } else {
                // User is signed out
                // ...
                // console.log("user is logged out")
                authContext?.dispatchAction({
                    type: AuthActions.SET_AUTH,
                    payload: {
                        email: undefined
                    }
                })
            }
        });
    }, [auth])

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                {/* App bar */}
                <AppBar />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme: Theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />

                    {/* Content box */}
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        {children}
                    </Container>

                    {/* Footer */}
                    <Footer title="Juan Marcos Vallejo" link="https://www.linkedin.com/in/marcos-vallejo-813718a8/"></Footer>
                </Box>
            </Box>


        </div>
    );
};