import { ReactNode, Reducer, useMemo, useReducer } from "react";
import { ActionType, OrdersContext, OrdersValues } from "./context";
import { ordersReducer } from "./reducer";

const initialValues: OrdersValues = {
    losses: 0,
    wins: 0,
    totalOrders: 0
};

type Props = {
    children?: ReactNode;
};

const OrdersProvider = ({ children }: Props) => {
    /* Gets the current value from the session storage, otherwise the default value */
    const [state, dispatchAction] = useReducer<
        Reducer<OrdersValues, ActionType>,
        OrdersValues
    >(ordersReducer, initialValues, (state) => state);

    const value = useMemo(() => ({ state, dispatchAction }), [state]);

    return <OrdersContext.Provider value={value}> {children} </OrdersContext.Provider>;
};

export default OrdersProvider;
